<!-- =========================================================================================
    File Name: ResetPassword.vue
    Description: Reset Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="h-screen flex w-full bg-img fontMedium">
    <div
      class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center mycard"
    >
      <vx-card>
        <div
          class=" absolute flex inset-x-0 top-0 mt-6 justify-center "
          slot="no-body"
        >
          <img
            src="@/assets/images/logo.png"
            style="max-width:150px ;"
          />
        </div>
        <div
          slot="no-body"
          class="full-page-bg-color"
          style="background: transparent radial-gradient(closest-side at 50% 50%, #2f5f80 0%, #003267 100%) 0% 0% no-repeat padding-box;
                box-shadow: -3px 0px 22px #00000029;
                         border-radius:31px 31px 31px 31px;
             opacity: 1;"
        >
          <div class="vx-row">
            <div
              class="vx-col hidden sm:hidden md:hidden text-center lg:block lg:w-1/2 mx-auto self-center"
            >
              <span
                style="text-align: center;
                 font: normal normal 100 30px/51px Futura PT;
                  letter-spacing: 0px;
                     color: #FFFFFF;
                     opacity: 1;"
              >
                {{ $t("payment") }}<P> </P>
              </span>
            </div>
            <div
              class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center  d-theme-dark-bg"
              style="border-radius: 31px 31px 31px 31px;"
            >
              <div class="p-8" style="margin-top: 60px;">
                <div class="vx-card__title mb-4">
                  <!-- <h4 class="mb-4">Reset password</h4>
                  <p>Please enter your new password.</p> -->
                </div>
                <div
                  class="vx-col  sm:w-full md:w-full  mx-auto self-center  d-theme-dark-bg"
                >
                  <div class="vx-row text-center">
                    <label class="col lg:w-1/4 w-full" for="fname"
                      >card holder:</label
                    >
                    <vs-input
                      name="fname"
                      class="col lg:w-2/3  w-full mb-3"
                      placeholder=""
                      v-model="value1"
                    />
                  </div>
                  <div class="vx-row text-center">
                    <label class="col lg:w-1/4 w-full" for="fname"
                      >card name:</label
                    >
                    <vs-input
                      name="fname"
                      class="col lg:w-2/3  w-full mb-3"
                      placeholder=""
                      v-model="value1"
                    />
                  </div>
                  <div class="vx-row text-center ">
                    <label class="col lg:w-1/4 w-full" for="fname"
                      >Expiry Date:</label
                    >
                    <vs-input
                      name="fname"
                      class="col lg:w-2/3  w-full mb-3"
                      placeholder=""
                      v-model="value1"
                    />
                  </div>
                  <div class="vx-row text-center">
                    <label class="col lg:w-1/4 w-full" for="fname">cvv:</label>
                    <vs-input
                      name="fname"
                      class="col lg:w-2/3 w-full mb-3"
                      placeholder=""
                      v-model="value1"
                    />
                  </div>
                </div>

                <div
                  class="flex flex-wrap justify-center flex-col-reverse sm:flex-row"
                >
                  <vs-button
                    class="w-full sm:w-auto text-center"
                    color="#004477"
                    @click="Resetpassword"
                    >{{ $t("ResetPassword") }}</vs-button
                  >
                </div>
                <div class="text-center">
                  <vs-button
                    class="w-full m-2 sm:w-auto mb-8 sm:mb-auto mt-3 sm:mt-auto text-primary"
                    type="line"
                    color="#004477"
                    to="/pages/login"
                    @click="payLater"
                    >{{ $t("payLater") }}</vs-button
                  >
                  <vs-button
                    type="line"
                    color="black"
                    to="/pages/login"
                    class="w-full m-12 sm:w-auto mb-8 sm:mb-auto mt-3 sm:mt-auto"
                    >{{ $t("Back") }}</vs-button
                  >
                </div>
                <!-- <vs-button type="line" line-origin="left" color="success">Success</vs-button> -->
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import moduleUser from "@/store/user/moduleUser.js";
export default {
  data() {
    return {
      passwordType: "password",
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,
      email: this.$route.params.email,
      password: "",
      code: null,
      password2: null
    };
  },
  methods: {
    showPassword() {
      if (this.passwordType == "text") this.passwordType = "password";
      else this.passwordType = "text";
    },
    Resetpassword() {
      debugger;
      var data = {
        code: this.code,
        email: this.email,
        password: this.password
      };
      this.$store
        .dispatch("UserList/ResetPassword", data)
        .then(res => {
          this.$vs.notify({
            color: "warning",
            title: "Reset Password",
            text: res.data
          });
          if (res.data == "reset done successfully") {
            this.$router.push("/pages/login");
          }
        })
        .catch(err => {
          console.error(err);
        });
    },
    checkpassword() {
      debugger;
      this.password_length = this.password.length;
      const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

      if (this.password_length > 8) {
        this.contains_eight_characters = true;
      } else {
        this.contains_eight_characters = false;
      }

      this.contains_number = /\d/.test(this.password);
      this.contains_uppercase = /[A-Z]/.test(this.password);
      this.contains_special_character = format.test(this.password);

      if (
        this.contains_eight_characters === true &&
        this.contains_special_character === true &&
        this.contains_uppercase === true &&
        this.contains_number === true
      ) {
        this.valid_password = true;
      } else {
        this.valid_password = false;
      }
    }
  },
  created() {
    if (!moduleUser.isRegistered) {
      this.$store.registerModule("UserList", moduleUser);
      moduleUser.isRegistered = true;
    }
  },
  mounted() {
    this.isMounted = true;
  }
};
</script>

<style>
.fontMedium {
  font-family: "futuraMedim";
}
.is_valid {
  color: rgba(136, 152, 170, 0.8);
}
.is_valid:before {
  width: 50%;
}
li {
  margin-bottom: 8px;
  color: #525f7f;
  position: relative;
}

li:before {
  content: "";
  width: 0%;
  height: 2px;
  background: #2ecc71;
  position: absolute;
  left: 0;
  top: 50%;
  display: block;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.mycard .vx-card {
  background: unset;
}
</style>
